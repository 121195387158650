.image-embed {
    position: relative;
    padding-top: 150px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
        max-height: 80vh;
        height: auto;
        width: auto;
        object-fit: contain;
    }
}


@media only screen and (max-width: 750px) {
    .image-embed {
        padding-top: 100px;
    }
}

@media only screen and (min-width : 751px) and (max-width: 1000px) {
    .image-embed {
        padding-top: 100px;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media only screen and (min-width : 1001px) and (max-width: 1250px) {
    .image-embed {
        padding-top: 100px;
        width: 100%;
        padding-left: 12%;
        padding-right: 12%;
    }

}