.scroll {
    position: absolute;

    width: 72px;
    height: 72px;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.2s ease;
    transition-delay: 0.15s;
    backdrop-filter: blur(10px);

    &:hover {
        .circle circle {
            stroke-dasharray: 95;
            stroke-dashoffset: 0;
        }

        .circle_container {
            width: 75%;
            height: 75%;
            transition: all .4s cubic-bezier(.51, .92, .24, 1.15);
        }

    }

    .circle {
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);

        circle {
            stroke-width: 2;
            stroke: var(--navigation-bar-color);
            fill: none;
            stroke-dasharray: 95;
            stroke-dashoffset: 95;
            transition: all 0.6s ease;
            stroke-linecap: round;
        }
    }
}

.arrow {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 1px;
    width: 10px;
    height: 10px;
}

.circle_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .25s cubic-bezier(.51, .92, .24, 1.15);
    transition-delay: .4s;
    border-radius: 50%;
    box-shadow: var(--box-shadow);

    circle {
        stroke-width: 2;
        stroke: rgba(var(--navigation-bar-color), .2);
        fill: var(--navigation-bar-color);
        stroke-linecap: round;
    }
}

.scroll.toggle:hover {
    background: rgba(var(--navigation-bar-color), 0.06);
}

.logo{
    position: absolute;
    color: var(--navigation-bar-text-color);
    top: 100%;
    left: 50%;
    transform: translate(-50%, -44%);
    margin-top: 1px;
    width: 120px;
    height: 120px;
    
    justify-content: center;
    display: flex;
}
