.contact-form{

    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__form {
        width: 100%;
        background-color: var(--contact-form-background);
        border-radius: 20px;
        padding: 30px;
        max-width: 700px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input{
            background-color: var(--contact-form-background);
        }

        input[type="text"],
        input[type="email"],
        textarea {
            color: var(--white-color);
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: 4px solid var(--primary-color);
            border-radius: 25px;
        }

        input[type="message"],
        textarea {
            color: var(--white-color);
            height: 150px;
            border-radius: 20px;
            background-color: var(--contact-form-background);
        }

        input[type="submit"] {
            width: 100%;
            padding: 10px;
            cursor: pointer;
            border-width: 4px;
        }

        button{
            width: 100%;
            //margin-left: -10px;
            //margin-right: -10px;
        }

    } 

}

.message-sent{
    padding: 300px 20% 20% 20%;
    text-align: center;
    width: 100%;
    
    h1, p{
        text-align: center;
    }
}
