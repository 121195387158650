.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
    opacity: 1;
    transition: opacity 1s ease-out;
    padding: 20px;

    &.fade-out {
        opacity: 0;
        visibility: hidden;
    }

    h1 {
        color: var(--white-color);
        font-weight: 500;
        font-size: 8rem;
        line-height: 10rem;
        margin: 0;
        text-align: center;
        margin-top: 40px;
    }
    
    h2{
        color: var(--white-color);
        opacity: 0.4;
        font-size: 1.7rem;
        margin-bottom: 20px;
        animation: fadeOpacity 0.5s infinite alternate;
    }

    &__loader{
        top: 20%;
    }
}

@keyframes fadeOpacity {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 0.4;
    }
}

@media only screen and (max-width: 550px) {
    .loading-screen h1 {
        font-size: 3rem;
        line-height: 5rem;
    }

    .loading-screen p {
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 551px) and (max-width: 758px) {
    .loading-screen h1 {
        font-size: 4rem;
        line-height: 6rem;
    }

    .loading-screen p {
        font-size: 1.75rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 990px) {
    .loading-screen h1 {
        font-size: 6rem;
        line-height: 8rem;
    }

    .loading-screen p {
        font-size: 2rem;
    }
}