.projects {
    &__item {
        padding-top: 150px;
        position: relative;
        padding-bottom: 200px;

        &__banner {
            position: relative;
            padding-left: 20%;
            padding-right: 20%;
            width: 100%;
            height: 600px;
            overflow: hidden;
            text-align: center;

            h1 {
                position: absolute;
                bottom: 5%;
                padding-left: 5%;
                padding-right: 20%;
                text-align: left;
                margin: 0 auto;
                z-index: 1;
                color: var(--white-color);
                font-size: 62pt;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 900;
                text-shadow: var(--projects-shadow-dimensions) var(--projects-title-shadow);

            }

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                opacity: 100%;
                filter: blur(0px) brightness(80%);
                //box-shadow: inset 0px -400px 66px #000; //WARNING: THIS PUTS THE INNER SHADOW BELOW THE IMAGE LOL
                border-radius: 20px;
                
            }
        }

        &__info{
            padding-top: 50px;
            padding-left: 20%;
            padding-right: 20%;
            box-shadow: none;
            background-color: transparent;
            //background-color: rgba(var(--navigation-bar-color) 0.5);
            min-height: 80px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: center;
            font-size: 1.2rem;
            top: 0;
            z-index: 10;
            //width: 80%;
 
            &__tags {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                flex-wrap: wrap;
        
                &__tag {
                    padding: 10px 20px;
                    margin-bottom: 10px;
                    border: none;
                    background-color: var(--primary-color);
                    color: var(--text-color);
                    cursor: default;
                    transition: background-color 0.3s ease;
                }
            }
            
            &__link{
                justify-content: right;
                display: flex;
                align-self:first baseline;
                flex: 1;
                margin-left: 50px;
                
            }

        }
    }
}


.projects__item__info__link .scroll {

    width: 50px;
    height: 50px;

    &:hover {

        .circle_container {
            box-shadow: none;
        }

    }

    .circle {

        circle {
            stroke: var(--tag-background-color);
            opacity: 70%;

        }
    }
}


.projects__item__info__link .circle_container {

    box-shadow: none;

    circle {
        stroke: rgba(var(--tag-background-color), 1);
        fill: var(--tag-background-color);
    }
}

.projects__item__info__link .scroll.toggle:hover {
    background: var(--tag-background-color);
    opacity: 70%;
}

.projects__item__info__link .logo{
    color: var(--white-color);
    opacity: 0.8;
    justify-self: center;
    align-items: center;
    transform: translate(-50%, -76%);
    width: 100px;
    height: 100px;

}

@media only screen and (max-width: 750px){
    .projects {
        
        &__item {
            padding-top: 0px;
            
            &__banner {
                padding-left: 0%;
                padding-right: 0%;
                height: 400px;

                h1 {
                    font-size: 32pt;
                }
                
                img{
                    border-radius: 0px;
                }
            } 

            &__info{
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    }
}

@media only screen and (min-width : 751px) and (max-width: 1000px){
    .projects {

        &__item {
            
            &__banner {
                padding-left: 10%;
                padding-right: 10%;
                height: 400px;

                h1 {
                    font-size: 40pt;
                }
            } 

            &__info{
                padding-left: 10%;
                padding-right: 10%;
            }
        }
    }
}

@media only screen and (min-width : 1001px) and (max-width: 1250px){
    .projects {
        &__item {
            
            &__banner {
                height: 500px;

                h1 {
                    font-size: 48pt;
                }
            }
        }
    }
}
