.home{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    &__text-wrapper{
        display: flex;
        align-content: center;
        justify-content: center;
        padding-top: 100px;

        h1{
            color: var(--white-color);
            margin: 0px;
            font-weight: 500; 
            font-size: 8rem;
            line-height: 10rem;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
        }
    }

    &__contact-me{
        display: flex;
        justify-content: center;

        button{
            margin-top: 15px;
            width: 680px;
        }
    }

    &__source-code {

        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 30px;
        text-align: right;
        font-size: 1.6rem;
        color: var(--white-color);
        opacity: 0.4;

        a {
            color: var(--white-color);
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width : 550px){

    .home{
        &__text-wrapper{
            h1{
                font-size: 4rem;
                line-height: 5rem;
            }
        }

        &__contact-me{
    
            button{
                width: 360px;
                font-size: 1.1rem;
            }
        }

        &__source-code {
            font-size: 1.4rem;

        }
    }
    
}

@media only screen and (min-width : 551px) and (max-width: 758px){
    .home{
        &__text-wrapper{
            h1{
                font-size: 4rem;
                line-height: 6rem;
            }
        }

        &__contact-me{
    
            button{
                width: 400px;
            }
        }
    }
}

@media only screen and (min-width : 759px) and (max-width : 990px){
    
    .home{
        &__text-wrapper{
            h1{
                font-size: 6rem;
                line-height: 8rem;
            }
        }

        &__contact-me{
    
            button{
                width: 560px;
            }
        }
    }
}