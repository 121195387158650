.iframeembed {
    position: relative;
    padding-top: 150px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &__overlay{
        position: absolute;
        border-radius: 10px;
        background-color: var(--white-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
        color: var(--primary-color);
        font-size: 18pt;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        opacity: 0.6;
        -webkit-animation: breathing 2s ease-out infinite normal;
        animation: breathing 2s ease-out infinite normal;
        cursor: pointer;

        &__disabled{
            display: none;
        }
    }

    &__iframe {
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }

    &__bigger-display-message {
        display: none;
        padding-top: 350px;
        padding-bottom: 330px;
        padding-left: 5%;
        padding-right: 5%;
        border: 4px solid var(--primary-color);
        border-radius: 20px;

        p {
            text-align: center;
        }
    }
}

@-webkit-keyframes breathing {
    0% {
      opacity: 0.6;
    }
  
    25% {
      opacity: 0.8;
    }
  
    60% {
      opacity: 0.6;
    }
  
    100% {
      opacity: 0.6;
    }
  }
  
  @keyframes breathing {
    0% {
      opacity: 0.6;
    }
  
    25% {
      opacity: 0.8;
    }
  
    60% {
      opacity: 0.6;
    }
  
    100% {
      opacity: 0.6;
    }
  }
  

@media only screen and (max-width: 750px) {
    .iframeembed {
        padding-top: 100px;

        &__iframe {
            display: none;
        }

        &__overlay{
            display: none;
        }

        &__bigger-display-message {
            display: block;
            padding-top: 150px;
            padding-bottom: 130px;
            width: 100%;
            border-width: 4px 0px 4px 0px;
            border-radius: 0px;

            p {
                font-size: 10pt;
            }
        }
    }
}

@media only screen and (min-width : 751px) and (max-width: 1000px) {
    .iframeembed {
        padding-top: 100px;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;

        &__overlay{
            display: none;
        }

        &__iframe {
            display: none;
        }

        &__bigger-display-message {
            display: block;
            padding-top: 200px;
            padding-bottom: 180px;

            p {
                font-size: 10pt;
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width : 1001px) and (max-width: 1250px) {
    .iframeembed {
        padding-top: 100px;
        width: 100%;
        padding-left: 12%;
        padding-right: 12%;

        &__overlay{
            display: none;
        }

        &__iframe {
            display: none;
        }

        &__bigger-display-message {
            display: block;
            padding-top: 200px;
            padding-bottom: 180px;
            width: 100%;

            p {
                font-size: 10pt;
            }
        }
    }

}